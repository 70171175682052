<template>
  <div id="excelLoading">
    <div class="inner">
      <h4>{{ $t('ExportExcel.text') }}</h4>
      <div class="pb-4"><div class="loader-bubble loader-bubble-primary"></div></div>
    </div>
  </div>

  <div id="pdfLoading">
    <div class="inner">
      <h4>{{ $t('ExportPDF.text') }}</h4>
      <div class="pb-4"><div class="loader-bubble loader-bubble-primary"></div></div>
    </div>
  </div>
    
</template>
<script>

export default {
  name: "app-loading",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  created() {
  },
  mounted() {
    
  },
  methods: {
  },
  computed: {
  },
}
</script>

