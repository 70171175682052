<template>
  <button class="dropdown-item ul-widget__link--font" @click="activate()">
    <i class="fas fa-check"></i>
    {{ $t('toggleStatus.text') }}
  </button>
</template>

<script>
export default {
  name: 'Activate',
  props: {
    id: Number,
    link :  String,
    method : String,
    status:Number
  },
  methods: {

    activate() {
      let status = 0 ;
      if(this.status == 0) {
        status = 1;
      }
      this.$axios({
        method:this.method,
        url: !this.id ? this.link : this.link+this.id,
        params : {
          'status' : status
        }
      })
          .then(response => {
            if(response.data.code === 200)  {
              this.$emit('activated')
              this.$toast.success(response.data.messages);
            }   else {
              this.$toast.error(response.data.messages);
            }
          });
    },
  }

}
</script>


