<template>
  <button v-if="icon !== 'true'" class="dropdown-item ul-widget__link--font" @click="remove()"> <i class="i-Close-Window"></i>  {{ $t('remove.text') }}</button>
  <i v-if="icon == 'true'" class="fas fa-trash-alt" @click="remove()"  title="delete"></i>

</template>

<script>
export default {
  name: 'Delete',
  props: {
    id: Number,
    link :  String,
    method : String,
    icon : String
  },
  methods: {
    remove() {
      this.$Swal.fire({
        title: this.$t('areYouSure.text'),
        text: this.$t('revertThis.text'),
        cancelButtonText: this.$t('cancel.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('deleteIt.text')
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios({
            method:this.method,
            url: !this.id ? this.link : this.link+this.id
          })
              .then(response => {
                if(response.data.code === 200)  {
                  this.$emit('deleted')
                  this.$toast.success(response.data.messages);
                }   else {
                  this.$toast.error(response.data.messages);
                }
              });
        }
      })

    }
  }

}
</script>


