import axios from 'axios';
export default {

    state: {
        adsData: localStorage.getItem('adsData') ? JSON.parse(localStorage.getItem('adsData')) : null,
        
    },
    getters: {
        getAdsData: state => state.adsData,

    },
    mutations: {
        setAdsData(state, data) {
            state.adsData = data;
        },
    },
    actions: {
        updateAdsData({ commit }, data) {
            commit('setAdsData', data);
        },
        async getAdsData({commit}){
            let response = await  axios.get('ads/active');
            if (response.data.code == 200) {
                commit('setAdsData',response.data.data);
            }
        },
       
    }
}
