<template>
  <div class="input-group-append" data-hint>
    <span class="text-theme input-group-text"><i class="fas fa-info"></i></span>
    <div class="hint text-theme">
      <h3>{{$t(code+'.helpTitle')}}</h3>
      <p>{{$t(code+'.helpDescription')}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Help',
  props: {
    code :  String,
  },


}
</script>
<style scoped>
h3:first-letter,
p:first-letter {
    text-transform: uppercase;
}
</style>


