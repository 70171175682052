<template>
  <div class="app-footer">
    <div class="row">
      <div class="col-md-9">{{$t('allRightsReserved.text')}}, <a href="" target="_blank">Zidney</a> &copy; {{new Date().getFullYear()}}</div>
<!--      <div class="col-md-3"><div class="d-flex justify-content-end"><a>{{$t('terms.text')}}</a></div></div>-->
    </div>
  </div>
</template>

<script>
export default {
name: "app-footer",
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }, methods: {
  }
}
</script>

<style scoped>
.app-footer {
  text-align: initial;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}
</style>