<template>

  <div class="form-group error" >

    <div class="text-danger" >
      <span>{{ message }}</span>

    </div>
  </div>

</template>
<script>

export default {
  props: ['message'],
}
</script>