<template>
 <div v-if="formValidation && isDirty && languages.length > 1">
    <div v-if="langs.$each.$invalid" style="width:calc(100% - 33px)" class="alert alert-danger text-center">{{ $t('alertLangValidation.text') }}</div>
 </div>
</template>
<script>

export default {
  props: ['formValidation', 'languages', 'isDirty', 'langs'],
}
</script>