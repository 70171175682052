export default {
    methods: {
      scrollToFirstError() {
        this.$nextTick(() => {
          const firstErrorInput = document.querySelector('.is-invalid');
  
          if (firstErrorInput) {
            firstErrorInput.focus();
  
            // Calculate the target scroll position
            const offset = 30;
            const rect = firstErrorInput.getBoundingClientRect();
            const targetScrollPosition = window.scrollY + rect.top - offset;
  
            // Scroll to the target position with an offset of 30px
            window.scrollTo({
              top: targetScrollPosition,
              behavior: 'smooth',
            });
          }
        });
      },
      addTabsHanle(tabs, tab){
        return tabs;
        if(!tabs.includes(tab)){
          tabs.push(tab);
        }
        return tabs;
      },
      removeTabsHanle(tabs, removedTabs){
        return tabs;
        removedTabs.forEach(el => {
          if(tabs.includes(el)){
            var index = tabs.indexOf(el);
            if (index > -1) {
              tabs.splice(index, 1);
            }
          }
          
        });
        return tabs;
      },
    },
  };