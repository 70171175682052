<template>
  <div class="customizer">
    <div class="handle"> <i class="i-Gear spin"></i> </div>
    <div class="customizer-body">
      <div class="accordion" id="accordionCustomizer">
        <div  class="changeColor text-center">
          <div class="card-header" id="headingOne">
            <p class="mb-0">Sidebar Colors</p>
          </div>
          <div class="collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionCustomizer">
            <div class="card-body">
              <div  class="colors sidebar-colors">

                <a class="color gradient-purple-indigo active" data-sidebar-class="sidebar-gradient-purple-indigo" data-theme-color="gradient-purple-indigo"><i class="i-Eye"></i></a>
                <a class="color gradient-black-blue" data-sidebar-class="sidebar-gradient-black-blue" data-theme-color="gradient-black-blue"><i class="i-Eye"></i></a>
                <a class="color gradient-black-gray" data-sidebar-class="sidebar-gradient-black-gray" data-theme-color="gradient-black-gray"><i class="i-Eye"></i></a>
                <a class="color gradient-ibiza-sunset" data-sidebar-class="sidebar-gradient-ibiza-sunset" data-theme-color="gradient-ibiza-sunset"><i class="i-Eye"></i></a>
                <a class="color dark-purple" data-sidebar-class="sidebar-dark-purple" data-theme-color="dark-purple"><i class="i-Eye"></i></a>
                <a class="color slate-gray" data-sidebar-class="sidebar-slate-gray" data-theme-color="slate-gray"><i class="i-Eye"></i></a>

                <a class="color gradient-color1" data-sidebar-class="sidebar-gradient-color1" data-theme-color="gradient-color1"><i class="i-Eye"></i></a>
                <a class="color gradient-color2" data-sidebar-class="sidebar-gradient-color2" data-theme-color="gradient-color2"><i class="i-Eye"></i></a>
                <a class="color gradient-color3" data-sidebar-class="sidebar-gradient-color3" data-theme-color="gradient-color3"><i class="i-Eye"></i></a>
                <a class="color gradient-color4" data-sidebar-class="sidebar-gradient-color4" data-theme-color="gradient-color4"><i class="i-Eye"></i></a>
                <a class="color gradient-color5" data-sidebar-class="sidebar-gradient-color5" data-theme-color="gradient-color5"><i class="i-Eye"></i></a>
                <a class="color gradient-color6" data-sidebar-class="sidebar-gradient-color6" data-theme-color="gradient-color6"><i class="i-Eye"></i></a>
                
                <a class="color gradient-color7" data-sidebar-class="sidebar-gradient-color7" data-theme-color="gradient-color7"><i class="i-Eye"></i></a>
                <a class="color gradient-color8" data-sidebar-class="sidebar-gradient-color8" data-theme-color="gradient-color8"><i class="i-Eye"></i></a>
                <a class="color gradient-color9" data-sidebar-class="sidebar-gradient-color9" data-theme-color="gradient-color9"><i class="i-Eye"></i></a>
                <a class="color gradient-color10" data-sidebar-class="sidebar-gradient-color10" data-theme-color="gradient-color10"><i class="i-Eye"></i></a>
                <a class="color gradient-color11" data-sidebar-class="sidebar-gradient-color11" data-theme-color="gradient-color11"><i class="i-Eye"></i></a>
                <a class="color gradient-color12" data-sidebar-class="sidebar-gradient-color12" data-theme-color="gradient-color12"><i class="i-Eye"></i></a>
                
                <div class="border-top w-100 my-2"></div>

                <a class="color midnight-blue" data-sidebar-class="sidebar-midnight-blue" data-theme-color="midnight-blue"><i class="i-Eye"></i></a>
                <a class="color blue" data-sidebar-class="sidebar-blue" data-theme-color="blue"><i class="i-Eye"></i></a>
                <a class="color indigo" data-sidebar-class="sidebar-indigo" data-theme-color="indigo"><i class="i-Eye"></i></a>
                <a class="color pink" data-sidebar-class="sidebar-pink" data-theme-color="pink"><i class="i-Eye"></i></a>
                <a class="color red" data-sidebar-class="sidebar-red" data-theme-color="red"><i class="i-Eye"></i></a>
                <a class="color purple" data-sidebar-class="sidebar-purple" data-theme-color="purple"><i class="i-Eye"></i></a>

                <a class="color orange" data-sidebar-class="sidebar-orange" data-theme-color="orange"><i class="i-Eye"></i></a>
                <a class="color yellow" data-sidebar-class="sidebar-yellow" data-theme-color="yellow"><i class="i-Eye"></i></a>
                <a class="color green" data-sidebar-class="sidebar-green" data-theme-color="green"><i class="i-Eye"></i></a>
                <a class="color teal" data-sidebar-class="sidebar-teal" data-theme-color="teal"><i class="i-Eye"></i></a>
                <a class="color cyan" data-sidebar-class="sidebar-cyan" data-theme-color="cyan"><i class="i-Eye"></i></a>
                <a class="color gray" data-sidebar-class="sidebar-gray" data-theme-color="gray"><i class="i-Eye"></i></a>
              
                <a class="color color7" data-sidebar-class="sidebar-color7" data-theme-color="color7"><i class="i-Eye"></i></a>
                <a class="color color8" data-sidebar-class="sidebar-color8" data-theme-color="color8"><i class="i-Eye"></i></a>
                <a class="color color9" data-sidebar-class="sidebar-color9" data-theme-color="color9"><i class="i-Eye"></i></a>
                <a class="color color10" data-sidebar-class="sidebar-color10" data-theme-color="color10"><i class="i-Eye"></i></a>
                <a class="color color11" data-sidebar-class="sidebar-color11" data-theme-color="color11"><i class="i-Eye"></i></a>
                <a class="color color12" data-sidebar-class="sidebar-color12" data-theme-color="color12"><i class="i-Eye"></i></a>
              
                <!--
                    <a @click="changeThemeColor('gradient-purple-indigo',$event)" class="color gradient-purple-indigo active" data-sidebar-class="sidebar-gradient-purple-indigo" data-theme-color="gradient-purple-indigo"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('gradient-black-blue',$event)" class="color gradient-black-blue" data-sidebar-class="sidebar-gradient-black-blue" data-theme-color="gradient-black-blue"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('gradient-black-gray',$event)" class="color gradient-black-gray" data-sidebar-class="sidebar-gradient-black-gray" data-theme-color="gradient-black-gray"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('gradient-ibiza-sunset',$event)" class="color gradient-ibiza-sunset" data-sidebar-class="sidebar-gradient-ibiza-sunset" data-theme-color="gradient-ibiza-sunset"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('dark-purple',$event)" class="color dark-purple" data-sidebar-class="sidebar-dark-purple" data-theme-color="dark-purple"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('slate-gray',$event)" class="color slate-gray" data-sidebar-class="sidebar-slate-gray" data-theme-color="slate-gray"><i class="i-Eye"></i></a>

                <a  @click="changeThemeColor('gradient-color1',$event)" class="color gradient-color1" data-sidebar-class="sidebar-gradient-color1" data-theme-color="gradient-color1"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color2',$event)" class="color gradient-color2" data-sidebar-class="sidebar-gradient-color2" data-theme-color="gradient-color2"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color3',$event)" class="color gradient-color3" data-sidebar-class="sidebar-gradient-color3" data-theme-color="gradient-color3"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color4',$event)" class="color gradient-color4" data-sidebar-class="sidebar-gradient-color4" data-theme-color="gradient-color4"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color5',$event)" class="color gradient-color5" data-sidebar-class="sidebar-gradient-color5" data-theme-color="gradient-color5"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color6',$event)" class="color gradient-color6" data-sidebar-class="sidebar-gradient-color6" data-theme-color="gradient-color6"><i class="i-Eye"></i></a>
                
                <a  @click="changeThemeColor('gradient-color7',$event)" class="color gradient-color7" data-sidebar-class="sidebar-gradient-color7" data-theme-color="gradient-color7"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color8',$event)" class="color gradient-color8" data-sidebar-class="sidebar-gradient-color8" data-theme-color="gradient-color8"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color9',$event)" class="color gradient-color9" data-sidebar-class="sidebar-gradient-color9" data-theme-color="gradient-color9"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color10',$event)" class="color gradient-color10" data-sidebar-class="sidebar-gradient-color10" data-theme-color="gradient-color10"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color11',$event)" class="color gradient-color11" data-sidebar-class="sidebar-gradient-color11" data-theme-color="gradient-color11"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('gradient-color12',$event)" class="color gradient-color12" data-sidebar-class="sidebar-gradient-color12" data-theme-color="gradient-color12"><i class="i-Eye"></i></a>
                
                <div class="border-top w-100 my-2"></div>

                <a  @click="changeThemeColor('midnight-blue',$event)" class="color midnight-blue" data-sidebar-class="sidebar-midnight-blue" data-theme-color="midnight-blue"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('blue',$event)" class="color blue" data-sidebar-class="sidebar-blue" data-theme-color="blue"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('indigo',$event)" class="color indigo" data-sidebar-class="sidebar-indigo" data-theme-color="indigo"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('pink',$event)" class="color pink" data-sidebar-class="sidebar-pink" data-theme-color="pink"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('red',$event)" class="color red" data-sidebar-class="sidebar-red" data-theme-color="red"><i class="i-Eye"></i></a>
                <a  @click="changeThemeColor('purple',$event)" class="color purple" data-sidebar-class="sidebar-purple" data-theme-color="purple"><i class="i-Eye"></i></a>

                <a @click="changeThemeColor('orange',$event)" class="color orange" data-sidebar-class="sidebar-orange" data-theme-color="orange"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('yellow',$event)" class="color yellow" data-sidebar-class="sidebar-yellow" data-theme-color="yellow"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('green',$event)" class="color green" data-sidebar-class="sidebar-green" data-theme-color="green"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('teal',$event)" class="color teal" data-sidebar-class="sidebar-teal" data-theme-color="teal"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('cyan',$event)" class="color cyan" data-sidebar-class="sidebar-cyan" data-theme-color="cyan"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('gray',$event)" class="color gray" data-sidebar-class="sidebar-gray" data-theme-color="gray"><i class="i-Eye"></i></a>
              
                <a @click="changeThemeColor('color7',$event)" class="color color7" data-sidebar-class="sidebar-color7" data-theme-color="color7"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color8',$event)" class="color color8" data-sidebar-class="sidebar-color8" data-theme-color="color8"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color9',$event)" class="color color9" data-sidebar-class="sidebar-color9" data-theme-color="color9"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color10',$event)" class="color color10" data-sidebar-class="sidebar-color10" data-theme-color="color10"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color11',$event)" class="color color11" data-sidebar-class="sidebar-color11" data-theme-color="color11"><i class="i-Eye"></i></a>
                <a @click="changeThemeColor('color12',$event)" class="color color12" data-sidebar-class="sidebar-color12" data-theme-color="color12"><i class="i-Eye"></i></a>
              
                -->
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">

          <div id="collapseTwo2" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionCustomizer">
            <div class="px-2">
              <label class="checkbox checkbox-primary">
                <input type="checkbox" @change="fromtoDarkMode" id="dark-checkbox">
                <span>Enable Dark Mode</span>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {  components: {

  },
  name: "app-customizer",
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  mounted() {
    
  },
  created() {

  },
  methods: {
    fromtoDarkMode(e) {
        if (!document.body.classList.contains('dark-theme')) {
            if (e.target.checked) {
                document.querySelector('.customizer .customizer-body .colors .color.active').classList.remove('active');
                document.querySelector('.customizer .customizer-body .colors .color.midnight-blue').classList.add('active');
                document.querySelector('.customizer .customizer-body .colors .color.midnight-blue').click();

            }
        }
    },

    changeThemeColor() {
        
    },

  },
  computed: {


  },
  watch: {

  },



}
</script>
