import axios from 'axios';
export default {

    state: {
        studenTracks: localStorage.getItem('studenTracks') ? JSON.parse(localStorage.getItem('studenTracks')) : null,
        studentSubjectTracks: localStorage.getItem('studentSubjectTracks') ? JSON.parse(localStorage.getItem('studentSubjectTracks')) : null,
        studentSubjectLessonsTracks: localStorage.getItem('studentSubjectLessonsTracks') ? JSON.parse(localStorage.getItem('studentSubjectLessonsTracks')) : null,
    },
    getters: {
        getStudentTracks: state => state.studenTracks,
        getStudentSubjectTracks: state => state.studentSubjectTracks,
        getStudentSubjectLessonsTracks: state => state.studentSubjectLessonsTracks,

    },
    mutations: {
        setStudenTracks(state, data) {
            state.studenTracks = data;
        },
        setStudentSubjectTracks(state, data) {
            state.studentSubjectTracks = data;
        },
        setStudentSubjectLessonsTracks(state, data) {
            state.studentSubjectLessonsTracks = data;
        },
    },
    actions: {
        updateStudenTracks({ commit }, data) {
            commit('setStudenTracks', data);
        },
        updateStudentSubjectTracks({ commit }, data) {
            commit('setStudentSubjectTracks', data);
        }, 
        updateStudentSubjectLessonsTracks({ commit }, data) {
            commit('setStudentSubjectLessonsTracks', data);
        },
        async getStudenTracks({commit}){
            let response = await  axios.get('students_tracks/get_by_status?status=onprogress');
            if (response.data.code == 200) {
                commit('setStudenTracks',response.data.data.student_tracks);
                commit('setStudentSubjectTracks',response.data.data.student_subject_tracks);
                commit('setStudentSubjectLessonsTracks',response.data.data.student_subject_lessons_tracks);
                
                   
            }
        },
       
    }
}
