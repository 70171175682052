<template>
  <div class="error" v-if="formValidation && isDirty && formValidation[inputToCheck].$errors.length">
    <div class="d-flex justify-content-between text-danger" v-for="error in formValidation[inputToCheck].$errors" :key="error">
      <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
      <span v-if="error.$validator == 'alphaNum'">{{$t('alphaMsg.text')}}</span>
      <span v-if="error.$validator == 'slugValidation'">{{$t('slugValidation.text')}}</span>
      <span v-if="error.$validator == 'double'">{{$t('double.text')}}</span>
      <span v-if="error.$validator == 'minValue'">{{$t('minValueNumber.text')}} {{error.$params.min}}</span>
      <span v-if="error.$validator == 'maxValue'">{{$t('maxValueNumber.text')}} {{error.$params.max}}</span>
    </div>
  </div>
</template>
<!--
    <template>
  <div class="form-group row error" v-if="formValidation && isDirty && formValidation[inputToCheck].$errors.length">
    <label class="col-sm-3 col-form-label" ></label>
    <div :class="customStyle ? 'ml-138' : '' " class="col-sm-9 d-flex justify-content-between" v-for="error in formValidation[inputToCheck].$errors" :key="error" style="color:red;">
      <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
      <span v-if="error.$validator == 'double'">{{$t('double.text')}}</span>
      <span v-if="error.$validator == 'minValue'">{{$t('minValueNumber.text')}} {{error.$params.min}}</span>
      <span v-if="error.$validator == 'maxValue'">{{$t('maxValueNumber.text')}} {{error.$params.max}}</span>
    </div>
  </div>
</template>
-->
<script>

import {useVuelidate} from '@vuelidate/core'

export default {
  setup() {
    return {v$: useVuelidate()}
  },
  props: ['formValidation', 'inputToCheck', 'isDirty', 'customStyle'],
}
</script>
<style scoped>
 .ml-138 {
   margin-left: 150px;
   margin-top: -11px;
 }
</style>