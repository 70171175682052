<template>
    <div class="app-all-wrap" data-theme-color="gradient-purple-indigo">
      <router-view></router-view>
    </div>
  </template>
  
  <!-- <template>
  <div class="app-all-wrap" data-theme-color="gradient-purple-indigo">
    <router-view></router-view>
  </div>
</template> -->
<script>
import AppFooter from '@/components/Layout/app-footer'
import AppHeader from '@/components/Layout/app-header.vue'
import AppCustomizer from '@/components/Layout/app-customizer.vue'
import AppSidebar from '@/components/Layout/app-sidebar.vue'
import AppLoading from "@/components/Layout/app-loading";

export default {
  name: 'App',
  components: {
    AppLoading,
    AppFooter,
    AppHeader,
    AppCustomizer,
    AppSidebar
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      showDashboard:false
    }
  },
  created() {
    this.showDashboard = true;
  },
  methods: {

  },
  mounted() {
  },
  computed: {

  },
  watch: {


  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
label:first-letter {
  text-transform: capitalize;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
