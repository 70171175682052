import axios from 'axios';
export default {

    state: {
        pathFullExams: localStorage.getItem('pathFullExams') ? JSON.parse(localStorage.getItem('pathFullExams')) : null,
        studentPathFullExams: localStorage.getItem('studentPathFullExams') ? JSON.parse(localStorage.getItem('studentPathFullExams')) : null,
        questionsPathFullExams: localStorage.getItem('questionsPathFullExams') ? JSON.parse(localStorage.getItem('questionsPathFullExams')) : null,
        answeredQuestionsPathFullExams: localStorage.getItem('answeredQuestionsPathFullExams') ? JSON.parse(localStorage.getItem('answeredQuestionsPathFullExams')) : [],
        skippedQuestionsPathFullExams: localStorage.getItem('skippedQuestionsPathFullExams') ? JSON.parse(localStorage.getItem('skippedQuestionsPathFullExams')) : [],
        favoriteQuestionsPathFullExams: localStorage.getItem('favoriteQuestionsPathFullExams') ? JSON.parse(localStorage.getItem('favoriteQuestionsPathFullExams')) : [],
        resultsPathFullExams: localStorage.getItem('resultsPathFullExams') ? JSON.parse(localStorage.getItem('resultsPathFullExams')) : {},
        examDurationPathFullExams: localStorage.getItem('examDurationPathFullExams') ? JSON.parse(localStorage.getItem('examDurationPathFullExams')) : '',
    },
    getters: {
        getPathFullExams: state => state.pathFullExams,
        getStudentPathFullExams: state => state.studentPathFullExams,
        getQuestionsPathFullExams: state => state.questionsPathFullExams,
        getAnswerQuestionPathFullExams: state => state.answeredQuestionsPathFullExams,
        getskippedQuestionsPathFullExams: state => state.skippedQuestionsPathFullExams,
        getFavoriteQuestionsPathFullExams: state => state.favoriteQuestionsPathFullExams,
        getResultsPathFullExams: state => state.resultsPathFullExams,
        getExamDurationPathFullExams: state => state.examDurationPathFullExams,
    },
    mutations: {
        setPathFullExams(state, data) {
            state.pathFullExams = data;
        },
        setStudentPathFullExams(state, data) {
            state.studentPathFullExams = data;
        },
        setQuestionsPathFullExams(state, data) {
            state.questionsPathFullExams = data;
        },
        setAnsweredQuestionsPathFullExams(state, answeredQuestionsPathFullExams) {
            state.answeredQuestionsPathFullExams = answeredQuestionsPathFullExams;
            localStorage.setItem('answeredQuestionsPathFullExams', JSON.stringify(state.answeredQuestionsPathFullExams));
        },
        setSkippedQuestionsPathFullExams(state, skippedQuestionsPathFullExams) {
            state.skippedQuestionsPathFullExams = skippedQuestionsPathFullExams;
            localStorage.setItem('skippedQuestionsPathFullExams', JSON.stringify(state.skippedQuestionsPathFullExams));
        },
        setFavoriteQuestionsPathFullExams(state, favoriteQuestionsPathFullExams) {
            state.favoriteQuestionsPathFullExams = favoriteQuestionsPathFullExams;
            localStorage.setItem('favoriteQuestionsPathFullExams', JSON.stringify(state.favoriteQuestionsPathFullExams));
        },
        setResultsQuestionsPathFullExams(state, resultsPathFullExams) {
            state.resultsPathFullExams = resultsPathFullExams;
            localStorage.setItem('resultsPathFullExams', JSON.stringify(state.resultsPathFullExams));
        },
        setDurationPathFullExams(state, examDurationPathFullExams) {
            state.examDurationPathFullExams = examDurationPathFullExams;
            localStorage.setItem('examDurationPathFullExams', JSON.stringify(state.examDurationPathFullExams));
        },
        RESET_GENERAL_PATHFULLEXAM_DATA(state) {
            state.pathFullExams = {};
        },
        RESET_STUDENT_PATHFULLEXAM_DATA(state) {
            state.studentPathFullExams = {};
        },
        RESET_QUETIONS_PATHFULLEXAM_DATA(state) {
            state.questionsPathFullExams = {};
        },
        RESET_ANSWERED_QUESTIONS_PATHFULLEXAM_DATA(state) {
            state.answeredQuestionsPathFullExams = [];
        },
        RESET_SKIPPED_QUESTIONS_PATHFULLEXAM_DATA(state) {
            state.skippedQuestionsPathFullExams = [];
        },
        RESET_FAVORITE_QUESTIONS_PATHFULLEXAM_DATA(state) {
            state.favoriteQuestionsPathFullExams = [];
        },
        RESET_RESULTS_PATHFULLEXAM_DATA(state) {
            state.resultsPathFullExams = {};
        },
        RESET_ExamDuration_PATHFULLEXAM_DATA(state) {
            state.examDurationPathFullExams = {};
        }
    },
    actions: {
        updatePathFullExams({ commit }, data) {
            commit('setPathFullExams', data);
        },
        updateStudentPathFullExams({ commit }, data) {
            commit('setStudentPathFullExams', data);
        },
        updateQuestionsPathFullExams({ commit }, data) {
            commit('setQuestionsPathFullExams', data);
        },
        updateAnsweredQuestionsPathFullExams({ commit }, data) {
            commit('setAnsweredQuestionsPathFullExams', data);
        },
        updateSkippedQuestionsPathFullExams({ commit }, data) {
            commit('setSkippedQuestionsPathFullExams', data);
        },
        updateFavoriteQuestionsPathFullExams({ commit }, data) {
            commit('setFavoriteQuestionsPathFullExams', data);
        },
        updateResultsQuestionsPathFullExams({ commit }, data) {
            commit('setResultsQuestionsPathFullExams', data);
        },
        updateDurationPathFullExams({ commit }, data) {
            commit('setDurationPathFullExams', data);
        },
        clearPathFullExams({ commit }) {
            commit('RESET_ANSWERED_QUESTIONS_PATHFULLEXAM_DATA');
            commit('RESET_STUDENT_PATHFULLEXAM_DATA');
            commit('RESET_QUETIONS_PATHFULLEXAM_DATA');
            commit('RESET_GENERAL_PATHFULLEXAM_DATA');
            commit('RESET_SKIPPED_QUESTIONS_PATHFULLEXAM_DATA');
            commit('RESET_FAVORITE_QUESTIONS_PATHFULLEXAM_DATA');
            commit('RESET_RESULTS_PATHFULLEXAM_DATA');
            commit('RESET_ExamDuration_PATHFULLEXAM_DATA');
        },
        async submitPathFullExams({ state, commit }) {
            const student_lesson_subject_track_id = 1;
            const student_subject_track_id = 1;
            const student_track_id = 3;
            const zidney_student_exam_id = state.studentPathFullExams.id;
            const answers = state.answeredQuestionsPathFullExams;
            try {
                const response = await axios.post('student-track-exam/result', { 
                    student_subject_track_id,
                    student_lesson_subject_track_id,
                    student_track_id,
                    zidney_student_exam_id,
                     answers });

                const { exam, zidey_exam, answers_exam } = response.data.data;
                const resultsPathFullExams = {
                    exam,
                    zidey_exam,
                    answers_exam

                };

                commit('setResultsQuestionsPathFullExams', resultsPathFullExams);

            } catch (error) {

                console.error(error);
            }
        },
        async submitSelfExam({ state, commit }) {
            const self_student_exam_id = state.studentPathFullExams.id;
            const answers = state.answeredQuestionsPathFullExams;
            
            try {
                const response = await axios.post('self-student-exams/result', { self_student_exam_id, answers });

                const { self_student_exam } = response.data.data;
                const resultsPathFullExams = {
                    self_student_exam,
                };

                commit('setResultsQuestionsPathFullExams', resultsPathFullExams);

            } catch (error) {

                console.error(error);
            }
        }
    }
}
