import { createStore } from 'vuex'
import auth from './modules/auth'
import dashboard from './modules/dashboard'
import generalExamData from './modules/generalExamData'
import generalZidneyExamData from './modules/generalZidneyExamData'
import pathFullExam from './modules/pathFullExam'
import pathMCQExam from './modules/pathMCQExam'
import ads from './modules/ads'
import studentPathOnProgress from './modules/studentPathOnProgress'
export default createStore({
  state: {

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    dashboard,
    generalExamData,
    generalZidneyExamData,
    pathFullExam,
    pathMCQExam,
    ads,
    studentPathOnProgress
  }
})
