<template>
  <div  class="sidebar-panel">
    <div class="gull-brand text-center p-4 d-flex justify-content-center align-items-center">
      <a>
        <!-- <i class="fas fa-home text-white fa-2x"></i> -->
         <img class="bg-white rounded" :src="`${publicPath}assets/images/logo.png`" width="100">
      </a>
      <div class="sidebar-compact-switch ml-auto"><span></span></div>
    </div>
    
    <div class="scroll-nav">
      <div class="side-nav">

        <div id="sidebarMenu" class="main-menu">

          <ul class="metismenu" id="menu">
            
                <li class="Ul_li--hover mm-actives">
                  <a href="/"><i class="i-Bar-Chart text-20 mr-2 text-muted"></i><span class="item-name text-15 text-muted">{{$t('dashboard.text')}}</span></a>
                </li>
<!-- 
                <li class="Ul_li--hover mm-actives ul_li-large">
                  <router-link :to="{name: 'MCQ'}" class="link" > 
                    <i class="fa-solid fas fa-list text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('MCQ.text') }} </span>
                  </router-link>
                </li>

                <li class="Ul_li--hover mm-actives ul_li-large">
                  <router-link :to="{name: 'ZidenyExam'}" class="link" > 
                    <i class="fa-solid fas fa-list text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> Zideny Exam </span>
                  </router-link>
                </li>

                </li> -->
                <li class="Ul_li--hover mm-actives ul_li-large">
                  <router-link :to="{name: 'StudentMCQExams'}" class="link" > 
                    <i class="fa-solid fas fa-list text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('mcqExam.text') }} </span>
                  </router-link>
                </li>

                <li class="Ul_li--hover mm-actives ul_li-large">
                  <router-link :to="{name: 'StudentZidneyExams'}" class="link" > 
                    <i class="fa-solid fas fa-list text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('zidneyExams.text') }} </span>
                  </router-link>
                </li>

                <!-- <li class="Ul_li--hover mm-actives ul_li-large">
                  <router-link :to="{name: 'LibraryStudent'}" class="link" > 
                    <i class="fa-solid fas fa-book text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('library.text') }} </span>
                  </router-link>
                </li> -->
                <li class="Ul_li--hover mm-actives ul_li-large">
                  <router-link :to="{name: 'librariesStudent'}" class="link" > 
                    <i class="fa-solid fas fa-book text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('library.text') }} </span>
                  </router-link>
                </li>
                <li class="Ul_li--hover mm-actives ul_li-large">
                  <router-link :to="{name: 'LivesStudent'}" class="link" > 
                    <i class="fab fa-slideshare text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('lives.text') }} </span>
                  </router-link>
                </li>
                <li class="Ul_li--hover mm-actives ul_li-large">
                  <router-link :to="{name: 'LearningPath'}" class="link" > 
                    <i class="fab fa-slideshare text-20 mr-2 text-muted"></i> <span class="item-name text-15 text-muted"> {{ $t('learningPath.text') }} </span>
                  </router-link>
                </li>
            <li class="Ul_li--hover mm-actives ul_li-large">
              <a @click.prevent="redirectUrl()" target="_blank">
                <i class="fa-solid fas fa-list text-20 mr-2 text-muted"></i>
                <span class="item-name text-15 text-muted"> {{ $t('discourse.text') }} </span>
              </a>
            </li>
                <!-- <li class="Ul_li--hover mm-actives ul_li-large">
                    <a :href="process.env.DISCOURSE_URL">
                      <i class="fa-solid fas fa-list text-20 mr-2 text-muted"></i> 
                      <span class="item-name text-15 text-muted"> {{ $t('discourse.text') }} </span>
                    </a>
                </li> -->


               

          </ul>


        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "app-sidebar",
  data() {
    return {
      currentSidebar : '',
      pages: [],
      publicPath: process.env.BASE_URL,
      Path: process.env.VUE_APP_API,
      menuIcons: ["i-Bar-Chart", "i-Library", "i-Suitcase", "i-Computer-Secure", "i-Computer-Secure", "i-File-Clipboard-File--Text", "i-File-Clipboard-File--Text", "i-File-Horizontal-Text", "i-Double-Tap", "i-Safe-Box1", "i-Bar-Chart", "i-File-Horizontal-Text", "i-Library",],
      loading: false,
      // menuTabs:[
      //   {
      //     path:"home",
      //     name:this.$t('dashboard.text'),
      //     icon:"i-Bar-Chart text-20 mr-2 text-muted"
      //   },
      //   {
      //     path:"ManagingWorkTeam",
      //     name:this.$t('managingWorkTeam.text'),
      //     icon:"fa-solid fa-people-group text-20 mr-2 text-muted"
      //   },
      //   {
      //     path:"WorkTeam",
      //     name:this.$t('workTeam.text'),
      //     icon:"fa-solid fa-people-carry-box text-20 mr-2 text-muted"
      //   },
      // ]
    }
  },
  mounted() {
  },
  methods: {
    redirectUrl() {
      window.location.href = this.Path +"discourse/user/"+ localStorage.getItem('token')
    },
  },
  computed: {
    changeMenu() {
      return this.$store.state.dashboardData.leftmenuScroller == 'e';
    },

  },
  watch:{
    '$i18n.locale': function() {
    }
  }
}
</script>

<style scoped>
.link {
  opacity: .8;
  font-size: 14px;
}
.router-link-active {
  opacity: 1;
}
.gull-brand img {
  background: #fff;
  padding: 5px;
}
[dir="rtl"] .metismenu .has-arrow:after {
  left: 5px !important;
  right: auto !important;
}
.metismenu .has-arrow:after {
  right: 5px !important;
}
[dir="rtl"] .sidebar-compact-switch.ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}
</style>
